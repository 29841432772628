// import React from 'react';
// import ServiceCard from '../components/ServiceCard';

// const Services = () => {
//   return (
//     <section>
//       <h2 className="text-3xl font-bold mb-6">Our Services</h2>
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//         <ServiceCard title="Makeup" description="Expert makeup services for all occasions." />
//         <ServiceCard title="Hair Styling" description="Professional hair styling at your convenience." />
//         <ServiceCard title="Skincare" description="Personalized skincare treatments for glowing skin." />
//       </div>
//     </section>
//   );
// };

// export default Services;
import React from 'react';

const Services = () => {
  return (
    <section className="py-12 px-4 bg-gray-50 min-h-screen">
      <h2 className="text-4xl font-bold text-center mb-12 text-pink-700">Our Services</h2>
      <div className="flex flex-wrap justify-center">
        <div className="w-80 bg-white shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
          <img src="https://dummyimage.com/600x400/000/fff" alt="Makeup" className="w-full h-40 object-cover rounded-t-lg mb-4" />
          <h3 className="text-2xl font-bold mb-2">Makeup</h3>
          <p className="text-gray-600 mb-4">Professional makeup services for all occasions, including weddings, parties, and photo shoots. We use high-quality products to ensure a flawless finish.</p>
          <ul className="list-disc list-inside text-gray-500 mb-4">
            <li>High-quality, cruelty-free products</li>
            <li>Experienced makeup artists</li>
            <li>Customized looks for each client</li>
          </ul>
          <p className="text-pink-500 font-bold mb-4">Starting at $50</p>
          <button className="bg-pink-500 text-white px-4 py-2 rounded hover:bg-pink-700 transition-colors">Book Now</button>
        </div>

        <div className="w-80 bg-white shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
          <img src="https://dummyimage.com/600x400/000/fff" alt="Hair Styling" className="w-full h-40 object-cover rounded-t-lg mb-4" />
          <h3 className="text-2xl font-bold mb-2">Hair Styling</h3>
          <p className="text-gray-600 mb-4">Expert hair styling at your convenience, tailored to your personal style and the latest trends. We offer cuts, coloring, and special event styles.</p>
          <ul className="list-disc list-inside text-gray-500 mb-4">
            <li>Latest hair trends and techniques</li>
            <li>Professional hair care products</li>
            <li>Personalized styling consultations</li>
          </ul>
          <p className="text-pink-500 font-bold mb-4">Starting at $40</p>
          <button className="bg-pink-500 text-white px-4 py-2 rounded hover:bg-pink-700 transition-colors">Book Now</button>
        </div>

        <div className="w-80 bg-white shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
          <img src="https://dummyimage.com/600x400/000/fff" alt="Skincare" className="w-full h-40 object-cover rounded-t-lg mb-4" />
          <h3 className="text-2xl font-bold mb-2">Skincare</h3>
          <p className="text-gray-600 mb-4">Personalized skincare treatments for glowing skin, including facials, exfoliation, and anti-aging therapies. Our services are designed to rejuvenate and refresh your skin.</p>
          <ul className="list-disc list-inside text-gray-500 mb-4">
            <li>Customized skin care regimens</li>
            <li>Natural and organic products</li>
            <li>Experienced skin care specialists</li>
          </ul>
          <p className="text-pink-500 font-bold mb-4">Starting at $60</p>
          <button className="bg-pink-500 text-white px-4 py-2 rounded hover:bg-pink-700 transition-colors">Book Now</button>
        </div>
      </div>
    </section>
  );
};

export default Services;
