import React, { useState } from 'react';

const Home = () => {
    const [faqOpen, setFaqOpen] = useState({});

  const toggleFaq = (index) => {
    setFaqOpen((prevFaqOpen) => ({
      ...prevFaqOpen,
      [index]: !prevFaqOpen[index],
    }));
  };
  return (
    <section className="text-center p-8 bg-gray-100 min-h-screen">
      <h2 className="text-4xl font-bold mb-6 text-pink-700">Welcome to Our Parlor</h2>
      <p className="mb-4 text-lg">Offering professional beauty services at your doorstep.</p>
      
      {/* Hero Section */}
      <div className="bg-cover bg-center h-96 mb-6" style={{ backgroundImage: "url('https://example.com/hero-image.jpg')" }}>
        <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
          <p className="text-white text-2xl">Experience the Best Beauty Services</p>
        </div>
      </div>

      
<div className="my-8 bg-white shadow-md rounded-lg p-6">
  <h3 className="text-3xl font-semibold mb-4 text-pink-700">About Us</h3>
  <p className="mb-4 text-lg">
    At [Parlor Name], we believe in the power of enhancing natural beauty through exceptional care and expertise. Founded by [Founder Name], our mission is to provide luxurious beauty services right to your doorstep, tailored to your unique needs and preferences.
  </p>
  <p className="mb-4 text-lg">
    Our team of experienced professionals is not only skilled in their craft but also passionate about empowering women through beauty. With a focus on continuous learning and using high-quality products, we ensure that every service we offer meets the highest standards.
  </p>
  <p className="mb-4 text-lg">
    What sets us apart is our commitment to a personalized beauty experience. We take the time to understand your needs, provide expert recommendations, and ensure you feel comfortable and confident every step of the way.
  </p>
  <div className="flex flex-wrap justify-center mt-6">
    <div className="w-64 text-center m-4">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Team Member 1" className="rounded-full w-32 h-32 mx-auto mb-2"/>
      <h4 className="text-xl font-bold">[Team Member 1]</h4>
      <p className="text-sm">Makeup Artist</p>
    </div>
    <div className="w-64 text-center m-4">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Team Member 2" className="rounded-full w-32 h-32 mx-auto mb-2"/>
      <h4 className="text-xl font-bold">[Team Member 2]</h4>
      <p className="text-sm">Hair Stylist</p>
    </div>
    <div className="w-64 text-center m-4">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Team Member 3" className="rounded-full w-32 h-32 mx-auto mb-2"/>
      <h4 className="text-xl font-bold">[Team Member 3]</h4>
      <p className="text-sm">Skincare Specialist</p>
    </div>
  </div>
</div>


      {/* Services Overview */}
      {/* Our Services */}
<div className="my-8">
  <h3 className="text-3xl font-semibold mb-6 text-center text-pink-700">Our Services</h3>
  <div className="flex flex-wrap justify-center">
    <div className="w-72 bg-pink-100 shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300 border ">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Makeup" className="w-full h-40 object-cover rounded-t-lg mb-4" />
      <h4 className="text-xl font-bold mb-2">Makeup</h4>
      <p className="text-gray-600">Professional makeup services for all occasions, including weddings, parties, and photo shoots. We use high-quality products to ensure a flawless finish.</p>
    </div>
    <div className="w-72 bg-pink-100 shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Hair Styling" className="w-full h-40 object-cover rounded-t-lg mb-4" />
      <h4 className="text-xl font-bold mb-2">Hair Styling</h4>
      <p className="text-gray-600">Expert hair styling at your convenience, tailored to your personal style and the latest trends. We offer cuts, coloring, and special event styles.</p>
    </div>
    <div className="w-72 bg-pink-100 shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Skincare" className="w-full h-40 object-cover rounded-t-lg mb-4" />
      <h4 className="text-xl font-bold mb-2">Skincare</h4>
      <p className="text-gray-600">Personalized skincare treatments for glowing skin, including facials, exfoliation, and anti-aging therapies. Our services are designed to rejuvenate and refresh your skin.</p>
    </div>
  </div>
</div>


      {/* Gallery */}
      <div className="my-8 bg-white shadow-md rounded-lg p-6">
        <h3 className="text-3xl font-semibold mb-4 text-pink-700">Gallery</h3>
        <div className="flex flex-wrap justify-center">
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 1" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 2" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
        </div>
      </div>

      {/* Testimonials */}
<div className="my-8 bg-gray-100 p-6 rounded-lg">
  <h3 className="text-3xl font-semibold mb-4 text-center text-pink-700">What Our Clients Say</h3>
  <div className="flex flex-wrap justify-center">
    <div className="w-80 bg-white shadow-md rounded-lg p-6 m-4 text-center">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Jane Doe" className="w-16 h-16 rounded-full mx-auto mb-4" />
      <p className="italic">"I love the makeup service. They are professional and friendly."</p>
      <p className="mt-4 font-bold">- Jane Doe</p>
    </div>
    <div className="w-80 bg-white shadow-md rounded-lg p-6 m-4 text-center">
      <img src="https://dummyimage.com/600x400/000/fff" alt="John Smith" className="w-16 h-16 rounded-full mx-auto mb-4" />
      <p className="italic">"Amazing hairstyling experience. I highly recommend them!"</p>
      <p className="mt-4 font-bold">- John Smith</p>
    </div>
    <div className="w-80 bg-white shadow-md rounded-lg p-6 m-4 text-center">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Alex Johnson" className="w-16 h-16 rounded-full mx-auto mb-4" />
      <p className="italic">"Their skincare treatment left my skin glowing. I feel refreshed!"</p>
      <p className="mt-4 font-bold">- Alex Johnson</p>
    </div>
  </div>
</div>


      {/* FAQs */}
      {/* FAQs */}
<div className="my-8 bg-white shadow-md rounded-lg p-6">
  <h3 className="text-3xl font-semibold mb-4 text-pink-700">FAQs</h3>
  <div className="w-full md:w-2/3 mx-auto text-left">
    <div className="bg-pink-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(1)}>
        Q: What services do you offer?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[1] ? 'block' : 'none' }}>
        A: We offer a range of beauty services including makeup, hair styling, and personalized skincare treatments.
      </p>
    </div>
    <div className="bg-pink-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(2)}>
        Q: How do I book an appointment?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[2] ? 'block' : 'none' }}>
        A: You can book an appointment by clicking the "Contact Us" button below or by calling us directly.
      </p>
    </div>
    <div className="bg-pink-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(3)}>
        Q: What are your operating hours?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[3] ? 'block' : 'none' }}>
        A: We operate from 9 AM to 7 PM, Monday through Saturday. Appointments outside these hours can be arranged on request.
      </p>
    </div>
    <div className="bg-pink-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(4)}>
        Q: Do you use cruelty-free products?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[4] ? 'block' : 'none' }}>
        A: Yes, we are committed to using only cruelty-free products in our services.
      </p>
    </div>
  </div>
</div>


      {/* Call-to-Action */}
      <div className="my-8 bg-pink-500 text-white p-6 rounded-lg">
        <h3 className="text-2xl font-bold mb-4">Book Your Appointment Today!</h3>
        <button className="bg-white text-pink-500 px-4 py-2 rounded hover:bg-pink-700 hover:text-white transition-colors">
          Contact Us
        </button>
      </div>
    </section>
  );
};

export default Home;
