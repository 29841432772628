import React from 'react';

const Contact = () => {
  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-pink-700 mb-8">Contact Us</h2>
        <p className="text-lg text-center text-gray-600 mb-12">
          We're here to help you with any questions or bookings. Reach out to us, and we’ll get back to you as soon as possible.
        </p>

        <div className="flex flex-col md:flex-row justify-between mb-12">
          <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
            <h3 className="text-2xl font-semibold text-pink-400 mb-4">Get in Touch</h3>
            <div className="flex items-center mb-4">
              <svg className="w-6 h-6 text-pink-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 2h20v20H2V2z"></path>
              </svg>
              <span className="text-lg text-gray-700">info@parlorbusiness.com</span>
            </div>
            <div className="flex items-center">
              <svg className="w-6 h-6 text-pink-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M15 10V5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5M21 15l-9 9-9-9"></path>
              </svg>
              <span className="text-lg text-gray-700">+123 456 7890</span>
            </div>
          </div>

          <div className="flex flex-col items-center md:items-end">
            <h3 className="text-2xl font-semibold text-pink-500 mb-4">Visit Us</h3>
            <p className="text-lg text-gray-700 text-center md:text-right">
              123 Beauty Street, Glamour City, BC 12345
            </p>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 md:p-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Send Us a Message</h3>
          <form className="space-y-4">
            <input
              type="text"
              placeholder="Your Name"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
              required
            />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
              required
            />
            <textarea
              placeholder="Your Message"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
              rows="4"
              required
            />
            <button
              type="submit"
              className="w-full bg-pink-500 text-white p-3 rounded-lg hover:bg-pink-600 transition"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
