import React from 'react';

const TrainerProfile = ({ name, bio }) => {
  return (
    <div className="bg-white shadow-md rounded-md p-4">
      <h3 className="text-lg font-bold text-pink-400">{name}</h3>
      <p>{bio}</p>
    </div>
  );
};

export default TrainerProfile;
